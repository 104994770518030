<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <h2 class="m-auto text-dark">OTP Verification</h2>

          <button
            class="btn btn-danger rounded-circle p-1"
            style="position: absolute; left: 87%"
            @click="show = false"
          >
            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </button>
        </div>

        <div class="modal-body">
          <form
            @submit.prevent="sendOtp"
            class="d-flex flex-column flex-wrap align-items-center justify-content-center"
          >
            <h5 class="m-0 text-dark">Enter OTP Code</h5>

            <div @paste="getClipboardData" class="otp-inputs my-5">
              <input
                v-model="otp1"
                type="text"
                maxlength="1"
                @input="autoFocus(otp1, 'input2')"
                @keydown.backspace="autoFocusBack(otp1, 'input1')"
                @paste.prevent
                ref="input1"
              />
              <input
                v-model="otp2"
                type="text"
                maxlength="1"
                @input="autoFocus(otp2, 'input3')"
                @keydown.backspace="autoFocusBack(otp2, 'input1')"
                @paste.prevent
                ref="input2"
              />
              <input
                v-model="otp3"
                type="text"
                maxlength="1"
                @input="autoFocus(otp3, 'input4')"
                @keydown.backspace="autoFocusBack(otp3, 'input2')"
                @paste.prevent
                ref="input3"
              />
              <input
                v-model="otp4"
                type="text"
                maxlength="1"
                @input="autoFocus(otp4, 'input5')"
                @keydown.backspace="autoFocusBack(otp4, 'input3')"
                @paste.prevent
                ref="input4"
              />
              <input
                v-model="otp5"
                type="text"
                maxlength="1"
                @input="autoFocus(otp5, 'input6')"
                @keydown.backspace="autoFocusBack(otp5, 'input4')"
                @paste.prevent
                ref="input5"
              />
              <input
                v-model="otp6"
                type="text"
                maxlength="1"
                @keydown.backspace="autoFocusBack(otp6, 'input5')"
                @paste.prevent
                ref="input6"
              />
            </div>

            <button type="submit" class="btn btn-gradient mt-1 mb-4 px-5 py-2">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";

// applibs
import thestore from "@/applibs/thestore.js";

// components
// import BtnRequestOtp from "../global/BtnRequestOtp.vue";

const toast = useToast();

export default {
  props: {
    show: Boolean,
  },
  components: {
    // BtnRequestOtp,
  },
  data() {
    return {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
      token: Cookies.get("jwt"),
    };
  },
  methods: {
    autoFocus(value, nextInput) {
      if (value.length === 1) {
        this.$refs[nextInput].focus();
      }
    },
    autoFocusBack(value, previousInput) {
      if (value.length === 0) {
        this.$refs[previousInput].focus();
      }
    },
    joinOtp() {
      return [
        this.otp1,
        this.otp2,
        this.otp3,
        this.otp4,
        this.otp5,
        this.otp6,
      ].join("");
    },
    sendOtp() {
      this.$emit("getOtpEvent", this.joinOtp());
    },
    getClipboardData(e) {
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("Text");

      this.pasteOtp(pastedData);
    },
    pasteOtp(data) {
      for (let i = 1; i <= 6; i++) {
        let otpKey = "otp" + i;
        this[otpKey] = data[i - 1];
      }
    },
    async requestOtp() {
      const res = await thestore.postRequestOtp(this.token);

      if (res.success) {
        toast.success("Please find the OTP in your email inbox.");
      } else {
        toast.warning(res.msg);
      }
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.requestOtp();
      }
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  /* height: 95%; */
  display: flex;
  flex-direction: column;
  margin: auto;
  /* padding: 20px 1.5rem; */
  /* background-color: #fff; */
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-body {
  /* position: relative; */
  /* margin: 20px 0; */
  /* height: 100%; */
  padding: 3rem 0;
  /* margin: 0 40px; */
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  /* -webkit-transform: scale(1); */
  /* transform: scale(1); */
}

.otp-inputs {
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
}

.otp-inputs input {
  width: 40px;
  height: 40px;
  border: none;
  border-bottom: 3px solid black;
  background-color: transparent;
  text-align: center;
  font-size: 2rem;
  color: black;
}

.otp-inputs input:focus {
  outline: none;
  border-color: #d26b11;
}

@media (max-width: 900px) {
  .modal-container {
    width: 55vw;
    height: 45vh;
  }
}

@media (max-width: 500px) {
  .modal-container {
    width: 95vw;
    height: 75%;
  }
}
</style>
