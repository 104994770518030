<template>
  <nav>
    <div class="nav_container">
      <div class="nav_wrapper">
        <router-link to="/">
          <img src="/images/astal-logo.png" alt="astal" width="134" />
        </router-link>

        <div class="nav_item">
          <ul>
            <li>
              <router-link to="/" class="nav_link">Beranda</router-link>
            </li>
            <li>
              <router-link to="/markets" class="nav_link">Pasar</router-link>
            </li>
            <li>
              <router-link :to="themain.defaultMarketPair" class="nav_link"
                >Pertukaran</router-link
              >
            </li>

            <!-- jika status kyc not approve, maka munculkan button kyc -->
            <li class="nav-item" v-if="user.orang_kyc_status == 'not approve'">
              <div class="nav_kyc">
                <button @click="showModalKyc = true">Mulai KYC</button>
              </div>
              <!-- <div @click="metamapHandler" class="nav_kyc">
                <button>Mulai KYC</button>
                <metamap-button
                  ref="metamapButton"
                  :clientid="metamap.metamapClientId"
                  :flowid="metamap.metamapFlowId"
                  :metadata="metadata"
                  class="metamap_btn"
                  :class="!metamap.metamapReady && 'pointer_events_none'"
                >
                </metamap-button>
              </div> -->
            </li>

            <!-- jika status kyc approve tetapi belum tandatangani agreement, bisa munculkan button ini -->
            <li
              class="nav-item"
              v-if="
                user.orang_kyc_status == 'approve' &&
                (user.orang_agreement == false || user.orang_agreement == null)
              "
            >
              <router-link
                to="/settings/agreement-sign"
                class="nav_link nav_kyc_update"
                >KYC Update</router-link
              >
            </li>

            <li
              class="nav-item dropdown header-custom-icon"
              v-if="token"
              @click="themain.openDropdown('user-notifs')"
              id="user-notifs"
            >
              <div
                class="nav-link dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="icon ion-md-notifications"></i>
                <span class="circle-pulse" v-if="notifs_count > 0"></span>
              </div>
              <div class="dropdown-menu">
                <div
                  class="dropdown-header d-flex align-items-center justify-content-between"
                >
                  <p class="mb-0 mr-4 font-weight-medium">
                    {{ notifs_count }} Pemberitahuan Baru
                  </p>
                  <div @click="postClearAllNotifs()" class="text-muted">
                    Bersihkan semua
                  </div>
                </div>
                <div class="dropdown-body">
                  <div
                    class="dropdown-item"
                    v-for="item in notifs"
                    :key="item.id"
                  >
                    <div class="icon">
                      <i class="icon ion-md-alert"></i>
                    </div>
                    <div class="content">
                      <p>{{ item.notif_text }}</p>
                      <p class="sub-text text-muted">
                        {{
                          moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- <div class="dropdown-footer d-flex align-items-center justify-content-center">
                  <a href="#!">View all</a>
                </div> -->
              </div>
            </li>
          </ul>

          <!-- <div
            v-if="token"
            @click="themain.openDropdown('user-menu')"
            v-bind:id="`user-menu`"
            class="nav-item dropdown header-img-icon"
          >
            <div
              class="nav-link dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="/assets/img/avatar.svg" alt="avatar" />
            </div>
            <div class="dropdown-menu">
              <div
                class="dropdown-header d-flex flex-column align-items-center"
              >
                <div class="figure mb-3">
                  <img src="/assets/img/avatar.svg" alt="" />
                </div>
                <div class="info text-center">
                  <p class="name font-weight-bold mb-0">
                    {{ user.orang_name }}
                  </p>
                  <p class="email text-muted mb-3">
                    @{{ user.orang_nickname }}
                  </p>
                </div>
              </div>
              <div class="dropdown-body">
                <ul class="profile-nav">
                  <li class="nav-item">
                    <router-link :to="`/settings/profile`" class="nav-link">
                      <i class="icon ion-md-person"></i>
                      <span>Profil</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="`/settings/wallets`" class="nav-link">
                      <i class="icon ion-md-wallet"></i>
                      <span>Dompet Saya</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link
                      @click="logoutAccount"
                      to="#"
                      class="nav-link red"
                    >
                      <i class="icon ion-md-power"></i>
                      <span>Keluar</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->

          <!-- new menu user dropdown -->
          <div v-if="token" @mouseleave="isMenuActive = false" class="dropdown">
            <div
              @mouseover="isMenuActive = true"
              class="dropdown-toggle"
              id="dropdownMenu"
            >
              <img src="/assets/img/avatar.svg" alt="avatar" />
            </div>

            <div class="menu_wrapper" :class="{ isHidden: !isMenuActive }">
              <div class="info text-center">
                <p class="name font-weight-bold mb-0">{{ user.orang_name }}</p>
                <p class="email text-muted mb-3">
                  @ {{ cutString(user.orang_nickname) }}
                </p>
              </div>
              <ul class="dropdown_menu">
                <li>
                  <a href="/settings/profile"
                    ><i class="icon ion-md-person"></i> Profil</a
                  >
                </li>
                <li>
                  <a href="/settings/wallets"
                    ><i class="icon ion-md-wallet"></i> Dompet Saya</a
                  >
                </li>
                <li @click="logoutAccount()">
                  <div class="logout_a">
                    <i class="icon ion-md-power"></i> Keluar
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div v-if="!token" class="btn_wrapper ml-5">
            <router-link to="/auth/login" class="btn_login">Masuk</router-link>
            <router-link to="/auth/register" class="btn_register"
              >Daftar</router-link
            >
          </div>
        </div>

        <!-- humburger menu -->
        <svg
          @click="showModal = true"
          class="hum_menu"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            opacity="0.2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.5 9.19995C2.5 8.93473 2.60536 8.68038 2.79289 8.49284C2.98043 8.30531 3.23478 8.19995 3.5 8.19995H13.808C14.0732 8.19995 14.3276 8.30531 14.5151 8.49284C14.7026 8.68038 14.808 8.93473 14.808 9.19995C14.808 9.46517 14.7026 9.71952 14.5151 9.90706C14.3276 10.0946 14.0732 10.2 13.808 10.2H3.5C3.23478 10.2 2.98043 10.0946 2.79289 9.90706C2.60536 9.71952 2.5 9.46517 2.5 9.19995ZM2.5 5.19995C2.5 4.93473 2.60536 4.68038 2.79289 4.49284C2.98043 4.30531 3.23478 4.19995 3.5 4.19995H17.5C17.7652 4.19995 18.0196 4.30531 18.2071 4.49284C18.3946 4.68038 18.5 4.93473 18.5 5.19995C18.5 5.46517 18.3946 5.71952 18.2071 5.90706C18.0196 6.09459 17.7652 6.19995 17.5 6.19995H3.5C3.23478 6.19995 2.98043 6.09459 2.79289 5.90706C2.60536 5.71952 2.5 5.46517 2.5 5.19995ZM2.5 13.2C2.5 12.9347 2.60536 12.6804 2.79289 12.4928C2.98043 12.3053 3.23478 12.2 3.5 12.2H17.5C17.7652 12.2 18.0196 12.3053 18.2071 12.4928C18.3946 12.6804 18.5 12.9347 18.5 13.2C18.5 13.4652 18.3946 13.7195 18.2071 13.9071C18.0196 14.0946 17.7652 14.2 17.5 14.2H3.5C3.23478 14.2 2.98043 14.0946 2.79289 13.9071C2.60536 13.7195 2.5 13.4652 2.5 13.2ZM2.5 17.2C2.5 16.9347 2.60536 16.6804 2.79289 16.4928C2.98043 16.3053 3.23478 16.2 3.5 16.2H13.808C14.0732 16.2 14.3276 16.3053 14.5151 16.4928C14.7026 16.6804 14.808 16.9347 14.808 17.2C14.808 17.4652 14.7026 17.7195 14.5151 17.9071C14.3276 18.0946 14.0732 18.2 13.808 18.2H3.5C3.23478 18.2 2.98043 18.0946 2.79289 17.9071C2.60536 17.7195 2.5 17.4652 2.5 17.2Z"
            fill="#EF7F1A"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 8.5C2 8.36739 2.05268 8.24021 2.14645 8.14645C2.24021 8.05268 2.36739 8 2.5 8H13.808C13.9406 8 14.0678 8.05268 14.1616 8.14645C14.2553 8.24021 14.308 8.36739 14.308 8.5C14.308 8.63261 14.2553 8.75979 14.1616 8.85355C14.0678 8.94732 13.9406 9 13.808 9H2.5C2.36739 9 2.24021 8.94732 2.14645 8.85355C2.05268 8.75979 2 8.63261 2 8.5ZM2 4.5C2 4.36739 2.05268 4.24021 2.14645 4.14645C2.24021 4.05268 2.36739 4 2.5 4H17.5C17.6326 4 17.7598 4.05268 17.8536 4.14645C17.9473 4.24021 18 4.36739 18 4.5C18 4.63261 17.9473 4.75979 17.8536 4.85355C17.7598 4.94732 17.6326 5 17.5 5H2.5C2.36739 5 2.24021 4.94732 2.14645 4.85355C2.05268 4.75979 2 4.63261 2 4.5ZM2 12.5C2 12.3674 2.05268 12.2402 2.14645 12.1464C2.24021 12.0527 2.36739 12 2.5 12H17.5C17.6326 12 17.7598 12.0527 17.8536 12.1464C17.9473 12.2402 18 12.3674 18 12.5C18 12.6326 17.9473 12.7598 17.8536 12.8536C17.7598 12.9473 17.6326 13 17.5 13H2.5C2.36739 13 2.24021 12.9473 2.14645 12.8536C2.05268 12.7598 2 12.6326 2 12.5ZM2 16.5C2 16.3674 2.05268 16.2402 2.14645 16.1464C2.24021 16.0527 2.36739 16 2.5 16H13.808C13.9406 16 14.0678 16.0527 14.1616 16.1464C14.2553 16.2402 14.308 16.3674 14.308 16.5C14.308 16.6326 14.2553 16.7598 14.1616 16.8536C14.0678 16.9473 13.9406 17 13.808 17H2.5C2.36739 17 2.24021 16.9473 2.14645 16.8536C2.05268 16.7598 2 16.6326 2 16.5Z"
            fill="#EF7F1A"
          />
        </svg>
      </div>
    </div>

    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <MenuModal
        :show="showModal"
        @close="showModal = false"
        :user="user"
        :themain="themain"
        :token="token"
        :logoutAccount="logoutAccount"
        :metadata="metadata"
      >
        <template #header>
          <div class="menu_head">
            <img src="/images/astal-logo.png" alt="astal" width="100" />
            <svg
              @click="showModal = false"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M24.9896 20L38.956 6.03369C39.6188 5.37202 39.9916 4.47414 39.9925 3.53757C39.9933 2.601 39.622 1.70247 38.9604 1.03963C38.2987 0.376789 37.4008 0.00394636 36.4643 0.00311924C35.5277 0.00229213 34.6292 0.373549 33.9663 1.03522L20 15.0015L6.03369 1.03522C5.37085 0.372379 4.47185 0 3.53445 0C2.59706 0 1.69806 0.372379 1.03522 1.03522C0.372379 1.69806 0 2.59706 0 3.53445C0 4.47185 0.372379 5.37085 1.03522 6.03369L15.0015 20L1.03522 33.9663C0.372379 34.6292 0 35.5282 0 36.4655C0 37.4029 0.372379 38.3019 1.03522 38.9648C1.69806 39.6276 2.59706 40 3.53445 40C4.47185 40 5.37085 39.6276 6.03369 38.9648L20 24.9985L33.9663 38.9648C34.6292 39.6276 35.5282 40 36.4655 40C37.4029 40 38.3019 39.6276 38.9648 38.9648C39.6276 38.3019 40 37.4029 40 36.4655C40 35.5282 39.6276 34.6292 38.9648 33.9663L24.9896 20Z"
                fill="#EF7F1A"
              />
            </svg>
          </div>
        </template>
      </MenuModal>

      <ModalKyc :show="showModalKyc" @close="showModalKyc = false" />
    </Teleport>
  </nav>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import moment from "moment";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import MenuModal from "../modals/MenuModal.vue";
import ModalKyc from "../modals/ModalKyc.vue";

export default {
  components: { MenuModal, ModalKyc },
  data() {
    return {
      themain,
      moment,

      showModal: false,
      showModalKyc: false,

      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),

      user: {},

      scrollPosition: 0,

      navbarScrolled: "",
      fixedTop: "",

      notifs: [],
      notifs_count: 0,

      metadata: "",

      isHover: false,
      isExpand: false,
      mostrarMenu: false,
      isMenuActive: false,

      metamap: {
        metamapReady: false,
        metamapClientId: null,
        metamapFlowId: null,
      },

      idmeta: {
        token: null,
        templateId: null,
        verificationId: null,
      },
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected - top nav");
    },

    /**
     * SEMUA_HALAMAN
     * Notifikasi KYC diletakkan disini supaya bisa di load dari halaman manapun
     * @param {*} data
     */
    kyc_status: function (data) {
      var self = this;

      // console.log('data kyc', data);

      if (data.uid == self.usid) {
        // jika disetujui, infokan untuk lihat email persetujuan
        if (data.message.toLowerCase() == "verified") {
          self.$router.push("/settings/agreement-sign");
        } else if (data.message == "reviewNeeded") {
          alert("KYC Anda sedang dalam proses review");
          self.$router.go();
        } else if (data.message == "rejected") {
          alert(`Status KYC Anda ditolak. Mohon dipastikan NIK Anda belum pernah terdaftar
          dan pastikan juga NIK yang Anda masukkan terlihat jelas.`);
          self.$router.go();
        }
      }
    },

    kyc_tutup_popup: function (data) {
      var self = this;
      if (data.uid == self.usid) {
        // pada FE, popup menutup sendiri. Jadi do nothing disini
        // console.log("tutup popup KYC");
      }
    },
  },

  mounted() {
    var self = this;

    if (
      self.$router.currentRoute._value.path == "/" ||
      self.$router.currentRoute._value.path == "/auth/login" ||
      self.$router.currentRoute._value.path == "/auth/register" ||
      self.$router.currentRoute._value.path == "/auth/forgot-password" ||
      self.$router.currentRoute._value.path == "/auth/passchange-form" ||
      self.$router.currentRoute._value.path == "/auth/two-factor-auth" ||
      self.$router.currentRoute._value.path ==
        "/auth/email-verification-token-request"
    ) {
      var selected = document.getElementById("login_menu_button");
      if (selected) {
        selected.style.visibility = "hidden";
      }
    }

    if (self.$router.currentRoute._value.path == "/") {
      self.fixedTop = "fixed-top";
    }

    // jika ada token, maka ambil data notifikasi dan juga tambahkan metadata
    if (self.token) {
      self.getMyData();
      self.getMyNotifs();

      const userId = Cookies.get("usid");
      self.metadata = `{"user_id":"${userId}"}`;
    }

    window.addEventListener("scroll", this.updateScrollPosition);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.updateScrollPosition);
  },

  methods: {
    abrirMenu() {
      this.mostrarMenu ? (this.mostrarMenu = false) : (this.mostrarMenu = true);
    },

    handleMenuAvatar() {
      this.isHover = true;
    },

    updateScrollPosition() {
      var self = this;
      this.scrollPosition = window.scrollY;

      if (this.scrollPosition > 200) {
        self.navbarScrolled = "navbar--scrolled";
      } else {
        self.navbarScrolled = "";
      }
    },

    async logoutAccount() {
      var self = this;

      var res = await thestore.postAuthLogout(self.token);
      if (res.success == true) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      } else {
        alert(res.msg);
      }
    },
    async getMyData() {
      var self = this;
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;
    },

    async getMyNotifs() {
      var self = this;
      var filter_rules = [{ field: "read_tf", op: "boolean", value: false }];
      var res = await thestore.getNotifs(
        self.token,
        1,
        1,
        "id",
        "desc",
        filter_rules
      );
      self.notifs = res.datas;
      self.notifs_count = res.datas.length;
    },

    async postClearAllNotifs() {
      var self = this;
      await thestore.postClearNotifs(self.token);
      self.notifs_count = 0;
    },

    handleMenuDropdown() {
      this.isMenuActive = !this.isMenuActive;
    },

    cutString(value) {
      if (value) {
        const firstString = value.substring(0, 5);
        const lastString = value.substring(value.length, value.length - 4);
        if (value.length >= 20) {
          return `${firstString}...${lastString}`;
        } else {
          return value;
        }
      }
    },

    async metamapHandler() {
      try {
        const { metaToken, templateId } = await thestore.getConfig();
        this.idmeta.templateId = res.templateId;
        this.idmeta.token = res.metaToken;
        console.log(metaToken, templateId);

        const res = await thestore.createVerification(metaToken, templateId);
        this.idmeta.verificationId = res.id;
        console.log("idMeta: ", res);
      } catch (errr) {
        console.log("Error: ", errr);
      }

      // if (!this.metamap.metamapReady) {
      //   const res = await thestore.getConfig();

      //   if (res.metamapClientId && res.metamapFlowId) {
      //     this.metamap.metamapClientId = res.metamapClientId;
      //     this.metamap.metamapFlowId = res.metamapFlowId;

      //     this.$refs.metamapButton.click();
      //   }

      //   this.metamap.metamapReady = true;
      // }
    },
  },
};
</script>

<style scoped>
nav {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #bababa;
  background: #fff;
  box-shadow: 0px 6px 27px 0px rgba(0, 0, 0, 0.05);
  padding: 10px 0;
}
.nav_container {
  width: 95%;
}
.nav_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav_item {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.nav_item ul {
  display: flex;
  align-items: center;
  gap: 56px;
  list-style: none;
}
.nav_link {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}
.nav_link:hover {
  color: #ef7f1a;
}
.btn_wrapper {
  display: flex;
  gap: 16px;
}
.btn_login {
  padding: 8px 33px;
  border-radius: 8px;
  border: none;
  background: var(
    --button,
    linear-gradient(224deg, #f6ba46 21.62%, #d0712b 93.87%)
  );
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
.btn_register {
  padding: 8px 33px;
  border-radius: 8px;
  border: 1px solid #000;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.user-menu-wrap {
  position: relative;
  width: 36px;
}
.user_mobile {
  margin: 50px auto;
}

.mini-photo-wrapper {
  display: block;
}

.mini-photo {
  border-radius: 50%;
}

.menu-container {
  visibility: hidden;
  opacity: 0;
}

.menu-container.active {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

ul {
  list-style: none;
}
.button_expand {
  display: none;
}

.dropdown-body {
  height: fit-content;
}
.profile-nav {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.profile-nav {
  gap: 10px !important;
}
.nav-link {
  color: #ef7f1a;
}

.hum_menu {
  display: none;
}
.menu_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.menu_head svg {
  cursor: pointer;
}

.nav_kyc {
  position: relative;
  cursor: pointer;
}

/* .nav_kyc button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ef7f1f;
  width: 100%;

  background: none;
  border: 1px solid #ef7f1f;
  border-radius: 3px;
} */

.nav_kyc button {
  /* color: #dd2730; */
  color: #ef7f1f;
  /* margin-left: -8px;
  margin-top: -2px; */
  width: 100%;

  background: none;
  border: 1px solid #ef7f1f;
  border-radius: 3px;
}

.nav_kyc_update {
  position: relative;
  cursor: pointer;
  background: none;
  border: 1px solid #ef7f1f;
  border-radius: 3px;
  color: #ef7f1f;
  padding: 2px 4px;
}

.metamap_btn {
  opacity: 0;
  width: 5.5rem;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown img {
  width: 40px;
  height: 40px;
}

.dropdown-toggle {
  cursor: pointer;
}

.menu_wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 10%;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  min-width: 160px;
  z-index: 1;
}

.dropdown_menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  gap: 10px !important;
}

.dropdown_menu li {
  width: 100%;
  list-style: none;
}

.dropdown_menu a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
}

.dropdown_menu a:hover {
  background-color: #f2f2f2;
}
.isHidden {
  display: none;
}
.logout_a {
  color: #d0712b;
  padding-left: 20px;
  cursor: pointer;
}
.pointer_events_none {
  pointer-events: none;
}

@media only screen and (max-width: 665px) {
  .nav_item {
    display: none;
  }
  .hum_menu {
    display: flex;
    cursor: pointer;
  }
}
</style>
