<template>
  <div class="position-relative">
    <div class="menu_wrapp">
      <div
        @click="handleDigitMenu"
        @mouseleave="isActive = false"
        class="dex_digit"
      >
        <div class="digit_item">
          <span>{{ valueSelected }}</span>
          <svg
            width="15"
            height="7"
            viewBox="0 0 19 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.2195 0L9.10975 10.9317L0 0H18.2195Z"
              fill="#707A8A"
            />
          </svg>
        </div>

        <div class="digit_lists" :class="{ menu_active: isActive }">
          <span @click="changeDigitFloat('0.0001')">0.0001</span
          ><span @click="changeDigitFloat('0.001')">0.001</span
          ><span @click="changeDigitFloat('0.01')">0.01</span
          ><span @click="changeDigitFloat('0.1')">0.1</span
          ><span @click="changeDigitFloat('1')">1</span>
        </div>
      </div>
    </div>

    <!-- Desktop -->
    <table class="table my-balances-table d-none d-lg-block">
      <thead>
        <tr>
          <th>Simbol</th>
          <th>Nama</th>
          <th>Dalam Proses</th>
          <th>Tersedia</th>
          <th>Total</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in balances" :key="item.id">
          <!-- Simbol -->
          <td>
            <img
              :src="item.ccurrency_obj.ccurrency_icon_data_url"
              :alt="item.ccurrency_name"
              width="20"
              style="margin-right: 10px; border-radius: 100%"
            />
            {{ item.ccurrency_symbol }}
          </td>

          <!-- Nama -->
          <td>
            {{ item.ccurrency_obj.ccurrency_name }}
          </td>

          <!-- Dalam Proses -->
          <td>
            {{ parseFloat(item.saldo_lock).numberFormat(floatDigit) }}
          </td>

          <!-- Tersedia -->
          <td>
            {{ parseFloat(item.saldo_value).numberFormat(floatDigit) }}
          </td>

          <!-- Total -->
          <td>
            {{ rowTotalBalance(item.saldo_lock, item.saldo_value) }}
          </td>
          <td>
            <!-- khusus IDR saja -->
            <div v-if="item.ccurrency_symbol == 'IDR'">
              <router-link
                :to="`/settings/wallets/idr_deposit`"
                class="btn btn-sm btn-outline-yellow mr-1"
              >
                Deposit
              </router-link>
              <router-link
                :to="`/settings/wallets/idr_withdraw`"
                class="btn btn-sm btn-outline-yellow"
              >
                Withdraw
              </router-link>
            </div>

            <!-- khusus BTC saja -->
            <div v-else-if="item.ccurrency_symbol == 'BTC'">
              <router-link
                :to="`/settings/wallets/btc_deposit`"
                class="btn btn-sm btn-outline-yellow mr-1"
              >
                Deposit
              </router-link>
              <router-link
                :to="`/settings/wallets/btc_withdraw`"
                class="btn btn-sm btn-outline-yellow"
              >
                Withdraw
              </router-link>
            </div>

            <!-- selainnya disini -->
            <div v-else>
              <router-link
                :to="`/settings/wallets/${item.ccurrency_symbol}/deposit`"
                class="btn btn-sm btn-outline-yellow mr-1"
              >
                Deposit
              </router-link>
              <router-link
                :to="`/settings/wallets/${item.ccurrency_symbol}/withdraw`"
                class="btn btn-sm btn-outline-yellow"
              >
                Withdraw
              </router-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Mobile -->
    <table class="table my-balances-table-vertical d-block d-lg-none">
      <div v-for="item in balances" :key="item.id" class="m-3 border">
        <!-- Simbol -->
        <div class="id_heading px-3 py-2">
          <img
            :src="item.ccurrency_obj.ccurrency_icon_data_url"
            :alt="item.ccurrency_name"
            width="20"
            style="margin-right: 10px; border-radius: 100%"
          />
          {{ item.ccurrency_symbol }}
        </div>
        <div class="p-3">
          <!-- Nama -->
          <div class="row row-cols">
            <div class="col-5 d-flex justify-content-between">
              <span>Nama</span> <span>:</span>
            </div>
            <div class="col-7">
              {{ item.ccurrency_obj.ccurrency_name }}
            </div>
          </div>

          <!-- Dalam Proses -->
          <div class="row row-cols">
            <div class="col-5 d-flex justify-content-between">
              <span>Dalam Proses</span> <span>:</span>
            </div>
            <div class="col-7">
              {{ parseFloat(item.saldo_lock).numberFormat(floatDigit) }}
            </div>
          </div>

          <!-- Tersedia -->
          <div class="row row-cols">
            <div class="col-5 d-flex justify-content-between">
              <span>Tersedia</span> <span>:</span>
            </div>
            <div class="col-7">
              {{ parseFloat(item.saldo_value).numberFormat(floatDigit) }}
            </div>
          </div>

          <!-- Total -->
          <div class="row row-cols">
            <div class="col-5 d-flex justify-content-between">
              <span>Total</span> <span>:</span>
            </div>
            <div class="col-7">
              {{ rowTotalBalance(item.saldo_lock, item.saldo_value) }}
            </div>
          </div>
        </div>
        <div>
          <!-- khusus IDR saja -->
          <div v-if="item.ccurrency_symbol == 'IDR'">
            <router-link
              :to="`/settings/wallets/idr_deposit`"
              class="btn btn-sm btn-outline-yellow w-50"
            >
              Deposit
            </router-link>
            <router-link
              :to="`/settings/wallets/idr_withdraw`"
              class="btn btn-sm btn-outline-yellow w-50"
            >
              Withdraw
            </router-link>
          </div>

          <!-- khusus BTC saja -->
          <div v-else-if="item.ccurrency_symbol == 'BTC'">
            <router-link
              :to="`/settings/wallets/btc_deposit`"
              class="btn btn-sm btn-outline-yellow w-50"
            >
              Deposit
            </router-link>
            <router-link
              :to="`/settings/wallets/btc_withdraw`"
              class="btn btn-sm btn-outline-yellow w-50"
            >
              Withdraw
            </router-link>
          </div>

          <!-- selainnya disini -->
          <div v-else>
            <router-link
              :to="`/settings/wallets/${item.ccurrency_symbol}/deposit`"
              class="btn btn-sm btn-outline-yellow w-50"
            >
              Deposit
            </router-link>
            <router-link
              :to="`/settings/wallets/${item.ccurrency_symbol}/withdraw`"
              class="btn btn-sm btn-outline-yellow w-50"
            >
              Withdraw
            </router-link>
          </div>
        </div>
      </div>
    </table>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import { mapState } from "vuex";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      pageObj: {
        page: 1,
        rows: 50,
        sort: "id",
        order: "asc",
        filter_rules: [],
      },
      balances: [],

      isActive: false,
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected -bal");
    },

    ubal: function (data) {
      var self = this;
      var obj = JSON.parse(data);
      obj.forEach(function (el) {
        // jika ada yang mengarah kepada kita, maka kita akan ubah data
        if (parseInt(self.usid) == el.u) {
          // disini kita sudah dapat 1 data saja
          // seperti: {"u":3,"BTC":"9.982423893170436","IDR":"999574426.8375854"}
          var balObjSocket = el;

          // iterasi semua balances
          self.balances.forEach(function (elbal, indexBalances) {
            // perulangan semua map dari balanceObjSocket
            // iterasi dari key: ['u', 'BTC', 'IDR']
            Object.keys(balObjSocket).forEach(function (balSocketKey) {
              if (elbal.ccurrency_symbol == balSocketKey) {
                self.balances[indexBalances].saldo_value =
                  balObjSocket[balSocketKey];
              }
            });
          });
        }
      });
    },
  },

  computed: {
    ...mapState(["valueSelected", "floatDigit"]),
  },

  async mounted() {
    var self = this;
    await self.getBalances();
  },

  methods: {
    async getBalances() {
      var self = this;

      var balanceRes = await thestore.getBalances(
        self.token,
        self.pageObj.page,
        self.pageObj.rows,
        self.pageObj.sort,
        self.pageObj.order,
        self.pageObj.filter_rules
      );

      self.balances = balanceRes.datas.filter(
        (item) => item.ccurrency_obj.ccurrency_show == "yes"
      );
    },

    rowTotalBalance(saldo_lock, saldo_value) {
      var self = this;
      var total = parseFloat(saldo_lock) + parseFloat(saldo_value);
      return total.numberFormat(self.floatDigit);
    },

    handleDigitMenu() {
      var self = this;

      self.isActive = !self.isActive;
    },

    changeDigitFloat(val) {
      var self = this;

      this.$store.dispatch("changeDigitFloat", val);

      self.getBalances();
    },
  },
};
</script>

<style scoped>
.my-balances-table thead tr,
.my-balances-table tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.my-balances-table td {
  padding: 5px;
  padding-left: 15px;
}

.my-balances-table tbody tr td,
.my-balances-table-vertical {
  word-wrap: break-word;
}

.menu_wrapp {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.dex_digit {
  position: relative;
  cursor: pointer;
}

.digit_item {
  width: 70px;
  border-radius: 3px;
  border: 1px solid #ff666d;
  padding: 0 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.digit_item span {
  color: #000;
}

.digit_lists {
  position: absolute;
  /* margin-right: 10px; */
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  background-color: #fff;
  border: 1px solid gray;
  border-radius: 3px;
  width: 70px;
  z-index: 999;

  transition-duration: 0.5s;
}

.menu_active {
  display: flex;
}

.digit_lists span {
  color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
}

.digit_lists span:hover {
  background-color: #ef7f1a;
}

.btn-outline-yellow {
  border: 1px solid #ef7f1a;
  color: #ef7f1a;
}

.btn-outline-yellow:hover {
  border: 1px solid #ce6a13 !important;
  color: #ce6a13 !important;
}

.my-balances-table-vertical {
  padding-bottom: 77px;
}

.my-balances-table-vertical .id_heading {
  font-weight: bold;
  background-color: #fafafa;
}
</style>
