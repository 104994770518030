<template>
  <div v-show="show" class="err_box">
    <p class="m-0 text-center">{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    message: String,
  },
};
</script>

<style scoped>
.err_box {
  max-width: 340px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e53935;
  color: white;
  border-radius: 5px;
  padding: 1rem;
  margin: 0 30px;
}
</style>
