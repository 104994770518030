<template>
  <div class="hero_container">
    <div class="hero_wrapper">
      <div class="hero_content">
        <div class="hero_side-left">
          <div class="hero_head">
            <h2>
              Raih Masa Depan Keuangan Anda dengan Pertukaran Kripto Terdepan
            </h2>
            <p>
              Temukan kemudahan investasi dan trading dengan teknologi terkini,
              dukungan pelanggan 24/7, dan akses ke beragam aset kripto
            </p>
          </div>

          <div class="hero_logos">
            <img src="/images/bappebti-black.png" alt="bappebti" />
            <img src="/images/kominfo-black.png" alt="kominfo" />
            <img src="/images/cbqa-kan-logo.png" alt="cbqa" />
          </div>
          <div class="hero_logos">
            <div class="cfx">
              <p>Member of</p>
              <img src="/images/cfx-logo-1.png" alt="cfx" />
            </div>
            <img src="/images/kki-logo-color.png" alt="kki" />
          </div>

          <!-- <div class="hero_count">
            <div class="count_item">
              <h2>9,501</h2>
              <p>Pengguna Terdaftar</p>
            </div>
            <div class="count_item">
              <h2>50</h2>
              <p>Aset Digital</p>
            </div>
            <div class="count_item">
              <h2>5M IDR</h2>
              <p>Transaksi Harian</p>
            </div>
          </div> -->
        </div>

        <div class="hero_side-right">
          <img src="/images/coin-logo.png" alt="coin" width="450" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.hero_container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
}
.hero_wrapper {
  width: 95%;
}
.hero_content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.hero_side-left {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.hero_head h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #ef7f1a;
  max-width: 519px;
}
.hero_head p {
  font-size: 18px;
  line-height: 27.3px;
  color: #667085;
  max-width: 519px;
}
.hero_side-left button {
  width: fit-content;
  padding: 8px 28px;
  border-radius: 10px;
  border: none;
  background: var(
    --button,
    linear-gradient(224deg, #f6ba46 21.62%, #d0712b 93.87%)
  );
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08),
    0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  font-size: 16px;
  font-weight: 600;
}
.hero_logos {
  display: flex;
  gap: 15px;
}
.hero_logos img {
  height: 56.5px;
  width: auto;
}
.hero_count {
  display: flex;
  gap: 40px;
}
.count_item h2 {
  text-align: center;
  font-family: Jost;
  font-size: 52.775px;
  font-style: normal;
  font-weight: 600;
  color: #ef7f1a;
}
.count_item p {
  color: #000;
  text-align: center;
  font-size: 14.379px;
  font-style: normal;
  font-weight: 500;
}

.hero_logos {
  .cfx {
    display: flex;
    align-items: center;
    gap: 16px;
    p {
      font-size: 16px;
      margin-bottom: 0px;
    }
  }
}

@media only screen and (max-width: 918px) {
  .hero_side-left {
    width: 300px;
  }
  .hero_head h2 {
    font-size: 30px;
    line-height: 36px;
  }
  .count_item h2 {
    font-size: 32.775px;
  }
}

@media only screen and (max-width: 800px) {
  .hero_count {
    gap: 20px;
  }
}

@media only screen and (max-width: 550px) {
  .hero_content {
    flex-direction: column;
  }
  .hero_container {
    height: auto;
    margin: 30px 0;
  }
  .hero_side-right img {
    width: 300px;
  }
  .hero_wrapper {
    width: 100%;
  }
  .hero_head h2 {
    font-size: 16px;
    line-height: 26px;
  }
  .count_item h2 {
    font-size: 16.775px;
  }
  .count_item p,
  .hero_head p {
    font-size: 14px;
  }
  .hero_logos img {
    height: 35px;
  }
}
</style>
<script setup lang="ts">
</script>