<template>
  <div>
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <HeadBalance
              :currency="currency"
              :activeCurrencyObj="ccurrencyObj"
            />

            <ul class="nav nav-pills mb-3">
              <li class="nav-item">
                <router-link
                  class="nav-link deposit-head text-white"
                  :to="`/settings/wallets/${currency}/deposit`"
                >
                  Deposit
                </router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link withdraw-head active">
                  Withdraw
                </a>
              </li>
            </ul>

            <!-- EVM Token -->
            <FormEvmCoin
              :currency="currency"
              :user="user"
              :ccurrencyObj="ccurrencyObj"
              v-if="
                ccurrencyObj.wd_config != null &&
                ccurrencyObj.wd_config.includes('EVMCoin') == true
              "
            />

            <!-- EVM Token -->
            <FormEvmToken
              :currency="currency"
              :user="user"
              :ccurrencyObj="ccurrencyObj"
              v-if="
                ccurrencyObj.wd_config != null &&
                ccurrencyObj.wd_config.includes('EVMToken') == true
              "
            />

            <WithdrawList :currency="currency" />
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";
import HeadBalance from "@/components/Settings/Wallets/HeadBalance.vue";
import FormEvmCoin from "@/components/Settings/Wallets/Withdraw/Form/EvmCoin.vue";
import FormEvmToken from "@/components/Settings/Wallets/Withdraw/Form/EvmToken.vue";
import WithdrawList from "@/components/Settings/Wallets/Withdraw/List.vue";

export default {
  components: {
    TopNav,
    Footer,
    Menu,
    HeadBalance,
    FormEvmCoin,
    FormEvmToken,
    WithdrawList,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      currency: this.$route.params.currency,
      ccurrencyObj: {
        ccurrency_config: {
          address_view: "",
          deposit_info_view: "",
          deposit_manual_view: "",
        },
        wd_config: [],
      },

      user: {},
      dataBanks: [],
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected -bal");
    },

    ubal: function (data) {
      var self = this;
      var obj = JSON.parse(data);
      obj.forEach(function (el) {
        // jika ada yang mengarah kepada kita, maka kita akan ubah data
        if (parseInt(self.usid) == el.u) {
          // disini kita sudah dapat 1 data saja
          // seperti: {"u":3,"BTC":"9.982423893170436","IDR":"999574426.8375854"}
          var balObjSocket = el;

          // iterasi semua balances
          self.balances.forEach(function (elbal, indexBalances) {
            // perulangan semua map dari balanceObjSocket
            // iterasi dari key: ['u', 'BTC', 'IDR']
            Object.keys(balObjSocket).forEach(function (balSocketKey) {
              if (elbal.ccurrency_symbol == balSocketKey) {
                self.balances[indexBalances].saldo_value =
                  balObjSocket[balSocketKey];
              }
            });
          });
        }
      });
    },
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    } else {
      self.$router.push("/");
    }
  },

  async mounted() {
    var self = this;
    await self.getCcurrencyData();
    await self.getUserData();
  },

  methods: {
    async getUserData() {
      var self = this;

      // ambil data dia ketika login
      if (self.token != null && self.token != "") {
        var res = await thestore.getMyProfileData(self.token);
        self.user = res;
      } else {
        self.$router.push("/");
      }
    },

    async getCcurrencyData() {
      var self = this;
      var filter_rules = [
        { field: "ccurrency_symbol", op: "equal", value: self.currency },
      ];
      var res = await thestore.getCurrencies(1, 1, "id", "desc", filter_rules);
      self.ccurrencyObj = res.datas[0];
    },
  },
};
</script>
