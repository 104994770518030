<template>
  <div>
    <div class="text-center" style="margin-bottom: 5rem">
      <h1>
        <span
          style="
            font-size: 26pt;
            font-family: Arial, sans-serif;
            color: black;
            background-color: transparent;
            font-weight: 400;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-alternates: normal;
            font-variant-numeric: normal;
            font-variant-east-asian: normal;
            font-variant-position: normal;
            font-variant-emoji: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre-wrap;
          "
          >KEBIJAKAN PRIVASI</span
        >
      </h1>
      <p style="font-size: 11pt">Terakhir Diperbarui: 2024-02-07</p>
    </div>

    <p class="mb-5">
      Kebijakan Privasi ini menjelaskan bagaimana PT Aset Instrumen Digital
      ("kami", "kita", atau "milik kami") mengumpulkan, menggunakan, dan
      melindungi informasi pribadi Anda ketika Anda menggunakan layanan
      pertukaran aset digital kami. Dengan menggunakan layanan kami, Anda
      menyetujui ketentuan Kebijakan Privasi ini.
    </p>

    <div class="mb-5">
      <h1 class="mb-3">
        <span class="list_heading">Informasi yang Kami Kumpulkan</span>
      </h1>

      <ol>
        <li>
          <span>Informasi Pribadi</span>: Saat Anda mendaftar untuk sebuah akun,
          kami mengumpulkan informasi pribadi seperti nama, alamat email, dan
          informasi lain yang Anda berikan.
        </li>
        <li>
          <span>Informasi Keuangan</span>: Untuk memfasilitasi transaksi, kami
          dapat mengumpulkan informasi keuangan seperti detail rekening bank
          Anda, riwayat transaksi, dan alamat dompet aset digital.
        </li>
        <li>
          <span>Informasi Identifikasi</span>: Untuk keperluan verifikasi, kami
          dapat mengumpulkan dokumen identifikasi seperti paspor atau KTP.
        </li>
        <li>
          <span>Informasi Teknis</span>: Kami mengumpulkan informasi teknis
          seperti alamat IP Anda, jenis browser, sistem operasi, dan waktu akses
          untuk meningkatkan keamanan dan fungsi layanan kami.
        </li>
        <li>
          <span>Data Penggunaan</span>
          : Kami mengumpulkan informasi tentang interaksi Anda dengan layanan
          kami, termasuk halaman yang Anda kunjungi, fitur yang Anda gunakan,
          dan riwayat transaksi Anda.
        </li>
      </ol>
    </div>

    <div class="mb-5">
      <h1 class="mb-3">
        <span class="list_heading">Cara Kami Menggunakan Informasi Anda</span>
      </h1>

      <p>Kami menggunakan informasi pribadi Anda untuk tujuan berikut:</p>

      <ol>
        <li>
          <span>Manajemen Akun</span>: Untuk membuat dan mengelola akun Anda,
          menyediakan dukungan pelanggan, dan berkomunikasi dengan Anda tentang
          akun Anda.
        </li>
        <li>
          <span>Pemrosesan Transaksi</span>: Untuk memproses transaksi Anda,
          mengelola kepemilikan aset digital Anda, dan menyediakan layanan
          terkait.
        </li>
        <li>
          <span>Kepatuhan dan Keamanan</span>: Untuk mematuhi persyaratan hukum
          dan peraturan, mencegah penipuan, dan meningkatkan keamanan platform
          kami.
        </li>
        <li>
          <span>Personalisasi</span>: Untuk mempersonalisasi pengalaman Anda dan
          menyediakan konten serta fitur yang disesuaikan.
        </li>
      </ol>
    </div>

    <div class="mb-5">
      <h1 class="mb-3">
        <span class="list_heading">Berbagi Informasi Anda</span>
      </h1>

      <p>
        Kami dapat berbagi informasi Anda dengan pihak ketiga dalam keadaan
        berikut:
      </p>

      <ol>
        <li>
          <span>Penyedia Layanan</span>: Kami dapat berbagi informasi Anda
          dengan penyedia layanan pihak ketiga yang membantu kami menyediakan
          layanan kami, seperti pemroses pembayaran, layanan verifikasi
          identitas, dan penyedia dukungan pelanggan.
        </li>
        <li>
          <span>Kepatuhan Hukum</span>: Kami dapat mengungkapkan informasi Anda
          kepada penegak hukum, otoritas regulasi, atau pihak ketiga lainnya
          ketika diwajibkan oleh hukum atau untuk melindungi hak hukum kami.
        </li>
        <li>
          <span>Transfer Bisnis</span>: Dalam hal merger, akuisisi, atau
          penjualan aset kami, informasi Anda mungkin akan ditransfer kepada
          pemilik baru.
        </li>
        <li>
          <span>Dengan Persetujuan Anda</span>: Kami dapat berbagi informasi
          Anda dengan pihak lain dengan persetujuan Anda atau atas arahan Anda.
        </li>
      </ol>
    </div>

    <div class="mb-5">
      <h1 class="mb-3">
        <span class="list_heading">Hak dan Pilihan Anda</span>
      </h1>

      <p>Anda memiliki hak berikut terkait informasi pribadi Anda:</p>

      <ol>
        <li>
          <span>Akses</span>: Anda dapat meminta akses ke informasi pribadi yang
          kami miliki tentang Anda.
        </li>
        <li>
          <span>Perbaikan</span>: Anda dapat meminta kami untuk memperbaiki
          informasi yang tidak akurat atau tidak lengkap tentang Anda.
        </li>
        <li>
          <span>Penghapusan</span>: Anda dapat meminta kami untuk menghapus
          informasi pribadi Anda, dengan ketentuan kewajiban hukum dan regulasi
          tertentu.
        </li>
      </ol>
    </div>

    <div class="mb-5">
      <h1 class="mb-3">
        <span class="list_heading">Keamanan Data</span>
      </h1>

      <p>
        Kami menerapkan langkah-langkah teknis dan organisasi yang sesuai untuk
        melindungi informasi pribadi Anda dari akses yang tidak sah, kehilangan,
        penyalahgunaan, atau perubahan. Namun, harap diperhatikan bahwa tidak
        ada metode transmisi melalui internet atau penyimpanan elektronik yang
        sepenuhnya aman.
      </p>
    </div>

    <div class="mb-5">
      <h1 class="mb-3">
        <span class="list_heading">Perubahan pada Kebijakan Privasi Ini</span>
      </h1>

      <p>
        Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu untuk
        mencerminkan perubahan dalam praktik kami atau persyaratan hukum. Kami
        akan memberitahu Anda tentang perubahan signifikan dengan memposting
        kebijakan yang diperbarui di situs web kami dan memperbarui tanggal
        "Terakhir Diperbarui".
      </p>
    </div>

    <div class="mb-5">
      <h1 class="mb-3">
        <span class="list_heading">Hubungi Kami</span>
      </h1>

      <p>
        Jika Anda memiliki pertanyaan atau kekhawatiran tentang Kebijakan
        Privasi ini atau praktik data kami, silakan hubungi kami di:
        info@astal.co.id
      </p>
    </div>
  </div>
</template>

<style scoped>
.text_h {
  list-style-type: none;
  margin-left: -20px;
  padding-top: 20px;
}

h2 {
  font-size: 2.5rem;
}
.txt_p,
.text_h,
h1,
h2,
strong {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}

li,
p {
  font-size: 16px;
}

li {
  list-style-type: decimal;
  font-size: 11pt;
  font-family: Arial, sans-serif;
  color: rgb(24, 24, 24);
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
}
li span {
  font-weight: bold;
}

ol {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-inline-start: 48px;
}

.ctr_trm {
  max-width: 932px;
  padding: 0 20px;
  z-index: 995;
}

.head_txt {
  font-size: 28px;
  margin-left: -20px;
}

.disclaimer {
  padding: 20px;
  margin-top: 40px;
  background-color: #fff3cd;
  color: gray;
}

.list_heading {
  font-size: 18pt;
  font-family: Arial, sans-serif;
  color: #252525;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-alternates: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-variant-position: normal;
  font-variant-emoji: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre-wrap;
}

@media (max-width: 800px) {
  h5.title {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .txt_p,
  .text_h,
  .head_txt,
  h1,
  h2,
  strong {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }

  li,
  p {
    font-size: 14px;
  }
}
</style>
